export const Primary = {
  50: "#f2eef6",
  100: "#d5cbe3",
  200: "#c1b2d6",
  300: "#a58fc3",
  400: "#9379b7",
  500: "#7858a5",
  600: "#6d5096",
  700: "#553e75",
  800: "#42305b",
  900: "#322545",
};

export const Secondary = {
  50: "#fef4ec",
  100: "#feeee3",
  200: "#fddcc4",
  300: "#f78f42",
  400: "#de813b",
  500: "#c67235",
  600: "#b96b32",
  700: "#945628",
  800: "#6f401e",
  900: "#563217",
};

export const Grey = {
  50: "#f1f1f1",
  100: "#d3d3d3",
  200: "#bdbdbd",
  300: "#9f9f9f",
  400: "#8d8d8d",
  500: "#8d8d8d",
  600: "#666666",
  700: "#505050",
  800: "#3e3e3e",
  900: "#2f2f2f",
};

export const Success = {
  50: "#eaf4ed",
  100: "#bfdcc7",
  200: "#a0cbac",
  300: "#74b386",
  400: "#59a46e",
  500: "#308d4a",
  600: "#2c8043",
  700: "#226435",
  800: "#1a4e29",
  900: "#143b1f",
};

export const Error = {
  50: "#fce9e9",
  100: "#f6baba",
  200: "#f19898",
  300: "#eb6969",
  400: "#e74c4c",
  500: "#e11f1f",
  600: "#cd1c1c",
  700: "#a01616",
  800: "#7c1111",
  900: "#5f0d0d",
};

export const Warning = {
  50: "#fff8ee",
  100: "#ffeacb",
  200: "#ffe0b2",
  300: "#ffd28e",
  400: "#ffc979",
  500: "#ffbc57",
  600: "#e8ab4f",
  700: "#b5853e",
  800: "#8c6730",
  900: "#6b4f25",
};

export const Info = {
  50: "#eef5fe",
  100: "#cbdefc",
  200: "#b2cffa",
  300: "#8fb9f8",
  400: "#79abf6",
  500: "#5896f4",
  600: "#5089de",
  700: "#3e6bad",
  800: "#305386",
  900: "#253f66",
};
