import React, { useState, useContext, useEffect } from "react";
import { Container } from "../../../helpers/GlobalStyle";
// import { Grey } from "../../../helpers/Colors";
import Typography from "../../atoms/Typography";
import { MovieGridWrapper, MovieGridBox } from "./../favourites/FavoritesStyle";
import MovieCard from "../../molecules/cards/MovieCard";
import {
  SrcGridWrapper,
  SrcGridBox,
  ToggleHolder,
  SelectedCat,
  DropdownWrapper,
  CatList,
} from "./MoviesStyle";
// import FeatherIcon from "feather-icons-react";
//popover
// import Popover from "@mui/material/Popover";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import useFetch from "../../../api/useFetch";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import CardSkeleton from "../../molecules/cards/skeleton";
import { CustomSelect } from "../../atoms/Input";
import { allData } from "../../../api/api";
import Button from "./../../atoms/Button";

const countries = [
  { id: "nigeria", name: "Nigeria" },
  { id: "ghana", name: "Ghana" },
  { id: "zambia", name: "Zambia" },
  { id: "tanzanian", name: "Tanzanian" },
  { id: "south africa", name: "South Africa" },
];
const languages = [
  { id: "english", name: "English" },
  { id: "french", name: "French" },
];

const SearchMevies = () => {
  let location = useLocation();

  const { getUser } = useContext(AuthContext);
  const user = getUser();

  const { data, loading, error } = useFetch(
    `/movies${location.search === "" ? "?q=" : location.search}&page=1&limit=10`
  );

  return (
    <>
      <>
        <Typography variant="h3" weight="bold">
          {data && data.length} Movie(s) Found
        </Typography>
        {loading ? (
          <MovieGridWrapper>
            <MovieGridBox>
              <CardSkeleton />
            </MovieGridBox>
            <MovieGridBox>
              <CardSkeleton />
            </MovieGridBox>
            <MovieGridBox>
              <CardSkeleton />
            </MovieGridBox>
          </MovieGridWrapper>
        ) : (
          <MovieGridWrapper>
            {data.data.map((movie, i) => (
              <MovieGridBox>
                <MovieCard
                  key={i}
                  id={movie.id}
                  url={movie.url}
                  rating={5.0}
                  cover={movie.poster.url}
                  title={movie.title}
                  genre={movie.genreID.name}
                  price={`${
                    user
                      ? Number(movie.price[user.data.currency]).toLocaleString()
                      : Number(movie.price.NGN).toLocaleString()
                  }`}
                  year={movie.year}
                />
              </MovieGridBox>
            ))}
          </MovieGridWrapper>
        )}
      </>
    </>
  );
};

const Movies = () => {
  const [genre, setGenre] = useState("");
  const [country, setCountry] = useState("");
  const [language, setLanguage] = useState("");
  const [genres, setGenres] = useState(null);
  // const [anchorGenreEl, setAnchorGenreEl] = useState(null);
  const [searchParams, setSeachParams] = useSearchParams();

  // const openGenre = Boolean(anchorGenreEl);

  const handleUrlChange = (update, val, field) => {
    update(val);
    const queryParams = Object.fromEntries([...searchParams]);
    if (queryParams[field]) {
      // update
      queryParams[field] = val;
      setSeachParams(queryParams);
    } else {
      // new
      setSeachParams({ ...queryParams, [field]: [val] });
    }
  };

  const getGenre = async () => {
    const res = await allData("", "/genres");
    setGenres(res.data);
  };

  useEffect(() => {
    getGenre();
  }, []);

  return (
    <Container>
      <SrcGridWrapper style={{ marginTop: 100 }}>
        <SrcGridBox>
          {genres ? (
            <CustomSelect
              label="Genre"
              value={genre}
              list={genres}
              onChange={(e) =>
                handleUrlChange(setGenre, e.target.value, "genreID")
              }
            />
          ) : (
            <>fetching...</>
          )}
        </SrcGridBox>

        <SrcGridBox>
          {genres ? (
            <CustomSelect
              label="Country"
              value={country}
              list={countries}
              onChange={(e) =>
                handleUrlChange(setCountry, e.target.value, "country")
              }
            />
          ) : (
            <>fetching...</>
          )}
        </SrcGridBox>

        <SrcGridBox>
          <CustomSelect
            label="Language"
            value={language}
            list={languages}
            onChange={(e) =>
              handleUrlChange(setLanguage, e.target.value, "language")
            }
          />
        </SrcGridBox>

        {/* <SrcGridBox>
          <ToggleHolder onClick={(e) => setAnchorGenreEl(e.currentTarget)}>
            <span>Genre</span>
            <SelectedCat>
              <span>All</span>
              <FeatherIcon icon={openGenre ? "chevron-up" : "chevron-down"} />
            </SelectedCat>
          </ToggleHolder>

          <Popover
            open={openGenre}
            anchorEl={anchorGenreEl}
            onClose={() => setAnchorGenreEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{ marginTop: 0.5 }}
          >
            <DropdownWrapper>
              {genres.map((genre, i) => (
                <CatList key={i}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: Grey[200],
                          "&.Mui-checked": {
                            color: Grey[200],
                          },
                        }}
                      />
                    }
                    label={genre}
                    sx={{ color: Grey[200], textTransform: "capitalize" }}
                  />
                </CatList>
              ))}
            </DropdownWrapper>
          </Popover>
        </SrcGridBox> */}
        <SrcGridBox>
          <Link to="/movies">
            <Button
              label="Clear Filter"
              style={{ height: 50, marginBottom: 20 }}
            />
          </Link>
        </SrcGridBox>
      </SrcGridWrapper>

      <SearchMevies />
    </Container>
  );
};

export default Movies;
