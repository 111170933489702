import { useContext } from "react";
import { Link } from "react-router-dom";
import {
  MovieCardWrapper,
  LinkWrapper,
  MovieCardImg,
  MovieRating,
  PriceWrap,
} from "./MovieCardStyle";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faHeart, faSpinner } from "@fortawesome/free-solid-svg-icons";
// import { faHeart } from "@fortawesome/free-regular-svg-icons";
import Typography from "./../../../atoms/Typography";
import { Grey, Primary, Success } from "../../../../helpers/Colors";
import Button from "./../../../atoms/Button";
import { AuthContext } from "../../../../context/AuthContext";
import moment from "moment/moment";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import { useEffect } from "react";
import { ToastContext } from "../../../../context/ToastContext";
import { allData } from "../../../../api/api";

const MovieCard = ({
  id,
  movie,
  url,
  rating,
  cover,
  title,
  genre,
  price,
  year,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFav, setIsFav] = useState(false);
  const { getUser } = useContext(AuthContext);
  const { displayToast } = useContext(ToastContext);
  const user = getUser();

  const handleFav = async () => {
    if (user) {
      setIsLoading(true);
      const data = await allData(user.token, `/movies/favorites/${id}`);
      if (data.status === "success") {
        setIsFav(!isFav);
      }
      setIsLoading(false);
    } else {
      displayToast("info", "login to favourite movie");
    }
  };

  useEffect(() => {
    if (user) {
      if (movie && movie.favorites) {
        let fav = movie.favorites.some((fav) => fav.userID === user.data._id);
        setIsFav(fav);
      }
    }
  }, []);

  return (
    <MovieCardWrapper>
      <LinkWrapper>
        <Link to={`/movies/${url}`}>
          <MovieCardImg style={{ background: `url(${cover})` }}>
            <MovieRating>
              {rating} <FontAwesomeIcon icon={faStar} color="#FFD700" />
            </MovieRating>
          </MovieCardImg>
        </Link>
      </LinkWrapper>
      <LinkWrapper>
        <Link to={url}>
          <Typography variant="h4">{title}</Typography>
        </Link>
      </LinkWrapper>
      <Typography
        variant="p1"
        style={{
          color: Grey[400],
          margin: "0px",
          marginTop: "10px",
          textTransform: "capitalize",
        }}
      >
        {genre} - {moment(year).format("MMM, YY")}
      </Typography>
      <PriceWrap>
        <Typography weight="bold" variant="p1" style={{ color: Success[400] }}>
          {`${user ? user.data.currency : "NGN"} ${price}`}
        </Typography>
        <Button
          type="text"
          label={
            isFav ? (
              <FontAwesomeIcon
                icon={isLoading ? faSpinner : faHeart}
                className={isLoading ? "spin" : ""}
                style={{ fontSize: "20px" }}
                color={Primary[500]}
              />
            ) : (
              <FeatherIcon
                icon={isLoading ? "loader" : "heart"}
                className={isLoading ? "spin" : ""}
                color="#f5f5f5"
                size={20}
              />
            )
          }
          onClick={handleFav}
        />
      </PriceWrap>
    </MovieCardWrapper>
  );
};

export default MovieCard;
