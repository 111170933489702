import React, { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { Container } from "../../../helpers/GlobalStyle";
import Breadcrumb from "../../molecules/Breadcrumb/Breadcrumb";
import Typography from "../../atoms/Typography";
import { AccountTap, AlertMsg } from "./AccountStyle";
import MyMovies from "./MyMovies";
import Wallet from "./Wallet";
import Profile from "./Profile";
//tabs
import { Box } from "@mui/material";
import { Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { AuthContext } from "../../../context/AuthContext";

const Account = () => {
  const [value, setValue] = useState("movies");
  let [params] = useSearchParams();
  const page = params.get("show");
  const { getUser } = useContext(AuthContext);
  const user = getUser().data;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (page !== null) {
      setValue(page);
    }
  }, []);

  return (
    <Container style={{ marginTop: 100 }}>
      <Breadcrumb PageName="my account" />
      <Typography variant="h1" style={{ paddingTop: 50 }}>
        my account
      </Typography>

      {(user.currency === undefined || user.currency === null) && (
        <AlertMsg>
          <Typography variant="h4" weight="bold">
            Set your preferred currency
          </Typography>
          <Typography variant="p2" weight="bold">
            Note that once set, can not be changed except account balance is at
            "0" regardless of the currency.
          </Typography>
        </AlertMsg>
      )}

      <AccountTap>
        <Box sx={{ width: "100%" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                textColor="inherit"
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Rented Movies"
                  value="movies"
                  disableRipple={true}
                />
                <Tab label="My Wallet" value="wallet" disableRipple={true} />
                <Tab label="Profile" value="profile" disableRipple={true} />
              </TabList>
            </Box>
            <TabPanel value="movies">
              <MyMovies />
            </TabPanel>
            <TabPanel value="wallet">
              <Wallet />
            </TabPanel>
            <TabPanel value="profile">
              <Profile />
            </TabPanel>
          </TabContext>
        </Box>
      </AccountTap>
    </Container>
  );
};

export default Account;
