import styled from "styled-components";

export const MovieGridWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const MovieGridBox = styled.div`
  width: 22%;
  box-sizing: border-box;
  padding: 10px;
  @media (max-width: 769px) {
    width: 50%;
  }
`;
