import { Input, CustomSelect } from "../../atoms/Input";
import Button from "../../atoms/Button";
import { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import Toast from "../../molecules/toast/Toast";

const PersonalDetailsForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [notify, showNotify] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");
  const [notifyType, setNotifyType] = useState("");
  const { getUser, updateUser } = useContext(AuthContext);
  const user = getUser();

  const handleNotify = (msg, type) => {
    showNotify(true);
    setNotifyMsg(msg);
    setNotifyType(type);
    setInterval(() => {
      showNotify(false);
    }, 5000);
  };

  const submitPersonalDetials = async (values) => {
    try {
      //   const res = await newUser(values);
      await updateUser(values);
      handleNotify("Changes saved", "success");
    } catch (err) {
      handleNotify(err.response.data.message, "error");
    }
  };

  const formik = useFormik({
    initialValues: {
      fullname: user.data.fullname,
      email: user.data.email,
      phone: user.data.phone,
      currency: user.data.currency,
    },
    validationSchema: Yup.object({
      fullname: Yup.string().required("Required"),
      email: Yup.string().email("invalid email address").required("Required"),
      phone: Yup.string().required("Required"),
      currency: Yup.string().required("Select preferred account currency"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      if (values.currency !== user.data.currency) {
        //user change currency, check if acc balance is 0

        const acc = user.data.wallet;
        if (acc !== 0) {
          handleNotify("Can not change currency", "error");
        } else {
          //save
          submitPersonalDetials(values);
        }
      } else {
        //save
        submitPersonalDetials(values);
      }

      setIsLoading(false);
    },
  });

  return (
    <div>
      <Toast show={notify} type={notifyType} msg={notifyMsg} />

      <form onSubmit={formik.handleSubmit}>
        <Input
          placeholder="Full Name"
          size="md"
          style={{ margin: "10px 0px" }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="fullname"
          value={formik.values.fullname}
          state={formik.touched.fullname && formik.errors.fullname && "error"}
          desc={
            formik.touched.fullname &&
            formik.errors.fullname &&
            formik.errors.fullname
          }
        />
        <Input
          placeholder="Phone Number"
          size="md"
          style={{ margin: "10px 0px" }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="phone"
          value={formik.values.phone}
          state={formik.touched.phone && formik.errors.phone && "error"}
          desc={
            formik.touched.phone && formik.errors.phone && formik.errors.phone
          }
        />
        <Input
          placeholder="Email"
          type="email"
          size="md"
          style={{ margin: "10px 0px" }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="email"
          value={formik.values.email}
          state={formik.touched.email && formik.errors.email && "error"}
          desc={
            formik.touched.email && formik.errors.email && formik.errors.email
          }
        />

        <CustomSelect
          label="Preferred Currency"
          list={[
            { name: "NGN", id: "NGN" },
            { name: "GHS", id: "GHS" },
            { name: "KES", id: "KES" },
            { name: "TZS", id: "TZS" },
            { name: "USD", id: "USD" },
            { name: "ZAR", id: "ZAR" },
            { name: "ZMW", id: "ZMW" },
          ]}
          value={formik.values.currency}
          onChange={formik.handleChange}
          name="currency"
          onBlur={formik.handleBlur}
          state={formik.touched.currency && formik.errors.currency && "error"}
          desc={
            formik.touched.currency &&
            formik.errors.currency &&
            formik.errors.currency
          }
        />

        <Button
          label="Save Changes"
          size="md"
          isLoading={isLoading}
          style={{ marginTop: 10 }}
        />
      </form>
    </div>
  );
};

export default PersonalDetailsForm;
