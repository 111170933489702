import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "../../atoms/Input";
import Button from "../../atoms/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContext } from "../../../context/ToastContext";

const PasswordOtpForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { displayToast } = useContext(ToastContext);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.number().required("Required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        navigate(`/reset-password?otp=${values.otp}`);
      } catch (err) {
        displayToast("error", "unexpected error happened");
      }
      setIsLoading(false);
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Input
          name="otp"
          placeholder="enter otp"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          size="md"
          state={formik.touched.otp && formik.errors.otp && "error"}
          desc={formik.touched.otp && formik.errors.otp && formik.errors.otp}
          style={{ marginBottom: 20 }}
        />
        <Button label="Submit" block={true} isLoading={isLoading} />
      </form>

      {/* <p>
        Know your password?{" "}
        <Link to="/login" style={{ textDecoration: "underline" }}>
          Log in here
        </Link>
      </p> */}
    </div>
  );
};

export default PasswordOtpForm;
