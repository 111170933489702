import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "../../atoms/Input";
import Button from "../../atoms/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContext } from "../../../context/ToastContext";
import { AuthContext } from "../../../context/AuthContext";

const ForgotPasswordForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { displayToast } = useContext(ToastContext);
  const { forgotPassword } = useContext(AuthContext);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("invalid email address").required("Required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await forgotPassword(values);
        displayToast("success", "Check your email for otp");
        navigate("/otp");
      } catch (err) {
        // handleNotify(err.response.data.message, "error");
        displayToast("error", err.response.data.message);
      }
      setIsLoading(false);
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Input
          name="email"
          placeholder="Email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          size="md"
          state={formik.touched.email && formik.errors.email && "error"}
          desc={
            formik.touched.email && formik.errors.email && formik.errors.email
          }
          style={{ marginBottom: 20 }}
        />
        <Button label="Submit" block={true} isLoading={isLoading} />
      </form>

      <p>
        Know your password?{" "}
        <Link to="/login" style={{ textDecoration: "underline" }}>
          Log in here
        </Link>
      </p>
    </div>
  );
};

export default ForgotPasswordForm;
