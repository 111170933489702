import { createContext, useState } from "react";
import axios from "./../axios/axios";

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);

  const getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };

  const addUser = (data) => {
    localStorage.setItem("user", JSON.stringify(data));
  };

  const newUser = async (values) => {
    const res = await axios.post("/auth", values);
    addUser(res.data);
    setIsAuth(true);
    return res.data;
  };

  const refreshUser = async () => {
    const user = getUser();
    const res = await axios.get(`/users/${user.data._id}`);
    user.data = res.data.data;
    addUser(user);
  };

  const login = async (values) => {
    const res = await axios.post("/auth/login", values);
    setIsAuth(true);
    addUser(res.data);
    return res.data;
  };

  const forgotPassword = async (values) => {
    const res = await axios.post("/auth/forgot-password", values);
    return res.data;
  };

  const resetPassword = async (values) => {
    const res = await axios.patch("/auth/reset-password", values);
    return res.data;
  };

  const updateUser = async (values) => {
    const user = getUser();

    await axios.patch("/users", values, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    //input saved changes into localStorage
    user.data.fullname = values.fullname;
    user.data.phone = values.phone;
    user.data.email = values.email;
    user.data.currency = values.currency;
    //update localStorage
    addUser(user);
  };

  const updatePwd = async (values) => {
    const user = getUser();
    const res = await axios.patch("/auth/update-password", values, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    addUser(res.data);
  };

  const logout = () => {
    setIsAuth(false);
    localStorage.removeItem("user");
  };

  const value = {
    isAuth,
    getUser,
    addUser,
    newUser,
    refreshUser,
    login,
    forgotPassword,
    resetPassword,
    updateUser,
    updatePwd,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
