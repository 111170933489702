import React from "react";
import { Link } from "react-router-dom";
import Typography from "../../atoms/Typography";
import RegisterForm from "../../organisms/forms/RegisterForm";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";

const Register = () => {
  const { isAuth, getUser } = useContext(AuthContext);

  if (isAuth || getUser() !== null) {
    return <Navigate to="/account" />;
  }
  return (
    <div>
      <Typography variant="h1">create an account</Typography>
      <p>
        Already have an account?{" "}
        <Link to="/login" style={{ textDecoration: "underline" }}>
          Sign In
        </Link>
      </p>
      <RegisterForm />
    </div>
  );
};

export default Register;
