import React from "react";
import { Link } from "react-router-dom";
import Typography from "../../atoms/Typography";
import { Breadcrumbs } from "./BreadcrumbStyle";
import FeatherIcon from "feather-icons-react";

const Breadcrumb = ({ PageName }) => {
  return (
    <Breadcrumbs>
      <Link to="/">Home</Link>
      <FeatherIcon icon="chevron-right" size="14" />
      <Typography variant="small1">{PageName}</Typography>
    </Breadcrumbs>
  );
};

export default Breadcrumb;
