import styled from "styled-components";
import { Primary, Grey } from "../../../helpers/Colors";

export const SrcGridWrapper = styled.div`
  position: relative;
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 769px) {
    width: 100%;
  }
`;

export const SrcGridBox = styled.div`
  width: 25%;
  box-sizing: border-box;
  padding: 5px 20px;
  padding-left: 10px;
  display: flex;
  align-items: flex-end;
  @media (max-width: 769px) {
    width: 50%;
  }
`;

export const ToggleHolder = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  background: ${Primary[900]};
  padding: 10px;
  cursor: pointer;
  color: ${Grey[300]};
`;

export const SelectedCat = styled.div`
  display: flex;
  align-items: center;
  span {
    color: ${Grey[100]};
    margin-right: 5px;
  }
`;

export const DropdownWrapper = styled.div`
  width: 500px;
  background: ${Primary[900]};
  padding: 10px;
  color: ${Grey[100]};
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 769px) {
    width: 300px;
  }
`;

export const CatList = styled.div`
  width: 50%;
`;
