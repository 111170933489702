import styled from "styled-components";
import { Primary } from "./../../../../helpers/Colors";

export const GenreCardWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  background-color: ${Primary[900]};
  overflow: hidden;
  background-size: cover !important;
  background-position: top !important;
`;

export const GenreCardOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  padding: 20px;
  display: flex;
  align-items: flex-end;
  color: #fff;
  h3 {
    margin: 0px;
  }
`;
