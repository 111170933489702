import styled from "styled-components";
import { Grey } from "../../../helpers/Colors";

export const Foot = styled.footer`
  margin-top: 30px;
  border-top: 0.5px solid ${Grey[900]};
  padding: 30px 0;
`;

export const FooterLogo = styled.div`
  display: block;
  @media (max-width: 710px) {
    display: flex;
    > img {
      margin: auto;
    }
  }
`;

export const FooterContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;

  @media (max-width: 710px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterMenu = styled.ul`
  padding-left: 0;
  > li {
    display: inline;
    list-style-type: none;
    padding-right: 70px;
  }
  a {
    text-decoration: none;
    color: #fff;
  }
  @media (max-width: 920px) {
    > li {
      padding-right: 30px;
    }
  }

  @media (max-width: 768px) {
    > li {
      padding-right: 20px;
    }
  }
  @media (max-width: 710px) {
    display: flex;
    flex-direction: column;
    text-align: center;
    > li {
      padding-bottom: 40px;
      padding-right: 0;
    }
  }
`;

export const FooterIcons = styled.div`
  display: flex;
  align-items: center;
`;

export const FooterBaseText = styled.div`
  margin-top: 20px;
  font-size: 14px;
  color: ${Grey[500]};
  display: flex;
  @media (max-width: 710px) {
    justify-content: center;
  }
`;
