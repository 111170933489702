import React from "react";
import { Container } from "../../../helpers/GlobalStyle";
import Typography from "./../../atoms/Typography";

const Pages = () => {
  return (
    <Container style={{ paddingTop: 50 }}>
      <Typography variant="h1">page title</Typography>
      <Typography variant="p2">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim minus
        aperiam fugiat cumque distinctio ad nihil saepe laborum iure. Quas iure
        cum pariatur inventore reiciendis nobis tenetur qui neque cumque!
      </Typography>
    </Container>
  );
};

export default Pages;
