import styled from "styled-components";
import { Primary } from "./../../../../helpers/Colors";

export const MovieCardWrapper = styled.div`
  width: 100%;
  /* max-width: 250px; */
  height: 450px;
  h4 {
    text-transform: capitalize;
  }
`;

export const LinkWrapper = styled.div`
  a {
    color: ${Primary[100]};
    text-decoration: none;
  }
`;

export const MovieCardImg = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  border-radius: 5px;
  background-color: ${Primary[900]};
  background-size: cover !important;
  background-position: top;
  margin-bottom: 20px;
`;

export const MovieRating = styled.div`
  position: absolute;
  padding: 5px 10px;
  right: 20px;
  top: 20px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
`;

export const PriceWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
