import { Input } from "../../atoms/Input";
import Button from "../../atoms/Button";
import { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import Toast from "../../molecules/toast/Toast";

const PasswordSettingsForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [notify, showNotify] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");
  const [notifyType, setNotifyType] = useState("");
  const { updatePwd } = useContext(AuthContext);

  const handleNotify = (msg, type) => {
    showNotify(true);
    setNotifyMsg(msg);
    setNotifyType(type);
    setInterval(() => {
      showNotify(false);
    }, 5000);
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Required"),
      newPassword: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await updatePwd(values);
        handleNotify("Changes saved", "success");
      } catch (err) {
        handleNotify(err.response.data.message, "error");
      }

      setIsLoading(false);
    },
  });

  return (
    <div>
      <Toast show={notify} type={notifyType} msg={notifyMsg} />

      <form onSubmit={formik.handleSubmit}>
        <Input
          placeholder="Current Password"
          size="md"
          type="password"
          style={{ margin: "10px 0px" }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="password"
          value={formik.values.password}
          state={formik.touched.password && formik.errors.password && "error"}
          desc={
            formik.touched.password &&
            formik.errors.password &&
            formik.errors.password
          }
        />
        <Input
          placeholder="New Password"
          type="password"
          size="md"
          style={{ margin: "10px 0px" }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="newPassword"
          value={formik.values.newPassword}
          state={
            formik.touched.newPassword && formik.errors.newPassword && "error"
          }
          desc={
            formik.touched.newPassword &&
            formik.errors.newPassword &&
            formik.errors.newPassword
          }
        />
        <Button
          label="Update Password"
          size="md"
          style={{ marginTop: 10 }}
          isLoading={isLoading}
        />
      </form>
    </div>
  );
};

export default PasswordSettingsForm;
