import React, { useState, useContext } from "react";
import { Container } from "../../../helpers/GlobalStyle";
import Typography from "../../atoms/Typography";
import Breadcrumb from "../../molecules/Breadcrumb/Breadcrumb";
import {
  MovieHeroSection,
  MovieHeroOverlay,
  MovieHeroWrapper,
  MovieRate,
  ParentPayment,
  PaymentWrapper,
  PaymentOption,
  PaymentHeader,
  ItemList,
} from "./MovieStyle";
import Button from "../../atoms/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import Modal from "@mui/material/Modal";
import { Grey, Success } from "../../../helpers/Colors";
import { AuthContext } from "../../../context/AuthContext";
import { ToastContext } from "../../../context/ToastContext";
import ModalVideo from "react-modal-video";
import "../../../../node_modules/react-modal-video/css/modal-video.css";
import { Link, useNavigate } from "react-router-dom";
import { DiscountInput } from "../../atoms/Input";
import { create } from "./../../../api/api";

const MovieHero = ({ data, rating }) => {
  const [playMovie, setPlayMovie] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApplying, setIsApplying] = useState(false);
  const [code, setCode] = useState("");
  const [discountState, setDiscountState] = useState("");
  const [discountDesc, setDiscountDesc] = useState("");
  const [discountCodeData, setDiscountCodeData] = useState(null);
  // const [discountAmt, setDiscountAmt] = useState(0);
  const { getUser } = useContext(AuthContext);
  const { displayToast } = useContext(ToastContext);
  const user = getUser();
  const currency = user ? user.data.currency : "NGN";
  let navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (discountCodeData) {
      //clear
      setDiscountState("");
      setCode("");
      setDiscountCodeData(null);
    } else {
      setIsApplying(true);
      try {
        const payload = { code };
        const res = await create(`/coupons/apply`, user.token, payload);

        if (res.status === "success") {
          setDiscountState("success");
          setDiscountCodeData(res.data);
          setDiscountDesc("");
        } else {
          setDiscountState("error");
          setDiscountDesc(res.message);
        }
      } catch (e) {
        console.log(e);
      }
      setIsApplying(false);
    }
  };

  const amt =
    data.price.hasOwnProperty(currency) && Number(data.price[currency]);
  const discountAmt = discountCodeData
    ? (discountCodeData.discountPercent / 100) * amt
    : 0;
  const totalAmt = amt - discountAmt;

  const handleRenting = async () => {
    setIsLoading(true);
    try {
      const payload = {
        movieID: data._id,
        currency,
        amount: totalAmt,
        discountAmount: discountAmt,
        discountID: discountCodeData ? discountCodeData._id : null,
      };
      const res = await create(`/rented-movies`, user.token, payload);

      if (res.status === "success") {
        displayToast("success", "Movie rented, you can watch now");
        navigate("/account?show=movies");
      } else {
        displayToast("error", res.message);
      }
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  //get youtube id from url
  var rx =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  const youTubeID = data.trailer.match(rx);
  return (
    <>
      <MovieHeroSection
        style={{
          backgroundImage: `url(${data.poster.url})`,
        }}
      >
        <MovieHeroOverlay>
          <Container style={{ marginTop: "200px" }}>
            <Breadcrumb PageName={data.title} />
            <MovieHeroWrapper>
              <Typography variant="h1">{data.title}</Typography>
              <MovieRate>
                <FontAwesomeIcon icon={faStar} color="#FFD700" />
                <FontAwesomeIcon icon={faStar} color="#FFD700" />
                <FontAwesomeIcon icon={faStar} color="#FFD700" />
                <FontAwesomeIcon icon={faStar} color="#FFD700" />
                <FontAwesomeIcon icon={faStar} color="#FFD700" />
                {/* <FeatherIcon icon="star" color="#FFD700" size="19" /> */}
                {rating}
              </MovieRate>

              <Button label="Rent Now" size="md" onClick={handleOpen} />
              <Button
                label="Watch Trailer"
                size="md"
                type="text"
                showIcon="left"
                onClick={() => setPlayMovie(true)}
                style={{ color: "#fff" }}
                icon={<FontAwesomeIcon icon={faPlayCircle} />}
              />
            </MovieHeroWrapper>
          </Container>
        </MovieHeroOverlay>
      </MovieHeroSection>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <ParentPayment>
          <PaymentHeader>
            <Typography
              variant="h1"
              color={`${Grey[100]}`}
              id="parent-modal-title"
              style={{ fontSize: "30px" }}
            >
              {data.title}
            </Typography>
          </PaymentHeader>

          <PaymentWrapper id="parent-modal-description">
            {user === null ? (
              <>
                <PaymentOption>
                  <Typography variant="p1" align="center">
                    Log into your account to proceed to renting movie
                  </Typography>
                </PaymentOption>
                <Link to="/login" style={{ width: "100%" }}>
                  <Button block={true} size="md" label="Login" />
                </Link>
              </>
            ) : (
              <>
                <PaymentOption>
                  <Typography variant="p1" align="center">
                    Renting fee will be deducted from your wallet to rent this
                    movie
                  </Typography>
                </PaymentOption>
                <DiscountInput
                  label="discount code"
                  state={discountState}
                  saved={discountCodeData && discountCodeData.discountPercent}
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  desc={discountDesc}
                  onSubmit={handleSubmit}
                  isLoading={isApplying}
                />

                <ItemList>
                  <Typography variant="p1">Renting Fee:</Typography>
                  <Typography variant="p1">
                    {`${user.data.currency} ${amt.toLocaleString()}`}
                  </Typography>
                </ItemList>

                <ItemList>
                  <Typography variant="p1">Discount:</Typography>
                  <Typography variant="p1">
                    {`${user.data.currency} ${discountAmt.toLocaleString()}`}
                  </Typography>
                </ItemList>

                <ItemList className="total">
                  <Typography variant="p1" weight="bold">
                    Total Renting Fee:
                  </Typography>
                  <Typography variant="p1" weight="bold">
                    <span style={{ color: `${Success[300]}` }}>
                      {`${user.data.currency} ${totalAmt.toLocaleString()}`}
                    </span>
                  </Typography>
                </ItemList>

                <Button
                  block={true}
                  size="md"
                  onClick={handleRenting}
                  label="Continue"
                  isLoading={isLoading}
                  disabled={isLoading}
                />
              </>
            )}
          </PaymentWrapper>
        </ParentPayment>
      </Modal>
      {/* trailer modal */}
      <ModalVideo
        channel="youtube"
        autoplay={true}
        isOpen={playMovie}
        videoId={youTubeID[1]}
        onClose={() => setPlayMovie(false)}
      />
    </>
  );
};

export default MovieHero;
