import React from "react";
import Typography from "../../atoms/Typography";
import LoginForm from "../../organisms/forms/LoginForm";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";

const Login = () => {
  const { isAuth, getUser } = useContext(AuthContext);

  if (isAuth || getUser() !== null) {
    return <Navigate to="/account" />;
  }

  return (
    <div>
      <Typography variant="h1">Sign In</Typography>
      <LoginForm />
    </div>
  );
};

export default Login;
