import React from "react";
import LoginWithButton from "./../atoms/Button/LoginWithButton";
import GoogleIcon from "./../../assets/images/icons/google_icon.png";
import FacebookIcon from "./../../assets/images/icons/facebook_icon.png";

const LoginWith = () => {
  return (
    <div>
      <LoginWithButton style={{ marginTop: 40 }}>
        <img
          alt="google icon"
          src={GoogleIcon}
          width={20}
          style={{ marginRight: 10 }}
        />{" "}
        Continue with Google
      </LoginWithButton>

      <LoginWithButton style={{ marginTop: 14 }}>
        <img
          alt="facebook icon"
          src={FacebookIcon}
          width={20}
          style={{ marginRight: 10 }}
        />{" "}
        Continue with Facebook
      </LoginWithButton>
    </div>
  );
};

export default LoginWith;
