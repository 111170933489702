import React from "react";
import { Link } from "react-router-dom";
import { GenreCardWrapper, GenreCardOverlay } from "./GenreCardStyle";
import Typography from "./../../../atoms/Typography";

const index = ({ cover, title, url }) => (
  <Link to={url}>
    <GenreCardWrapper style={{ background: `url(${cover})` }}>
      <GenreCardOverlay>
        <Typography variant="h3" style={{ textTransform: "capitalize" }}>
          {title}
        </Typography>
      </GenreCardOverlay>
    </GenreCardWrapper>
  </Link>
);

export default index;
