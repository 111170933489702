import { createContext, useState } from "react";

const ToastContext = createContext(null);

const ToastProvider = ({ children }) => {
  const [toastShow, setToastShow] = useState(false);
  const [toastType, setToastType] = useState("");
  const [toastMsg, setToastMsg] = useState("");

  const displayToast = (type, msg) => {
    setToastShow(true);
    setToastType(type);
    setToastMsg(msg);
    setInterval(() => {
      setToastShow(false);
    }, 5000);
  };

  const value = {
    toastShow,
    toastType,
    toastMsg,
    displayToast,
  };

  return (
    <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
  );
};

export { ToastContext, ToastProvider };
