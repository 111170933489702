import { useEffect, useState } from "react";
import axios from "../axios/axios";

const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const getData = async () => {
    await axios
      .get(url)
      .then((res) => setData(res.data))
      .catch((err) => setError(err));
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [url]);
  return { data, loading, error };
};

export default useFetch;
