import styled from "styled-components";
import { Grey, Primary } from "../../../helpers/Colors";
import { InputStyle } from "../../atoms/Input/InputStyle";

export const Nav = styled.nav`
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 100000;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  padding: 10px 0;
  color: #fff;
`;
export const NavMenu = styled.div`
  > ul > li {
    display: inline;
    padding: 0 15px;
  }
  a {
    color: #fff;
    text-decoration: none;
  }
  a:hover {
    color: #ccc;
  }
  @media (max-width: 1200px) {
    > ul > li {
      padding: 10px;
    }
  }
  @media (max-width: 920px) {
    display: none;
  }
`;
export const NavLinks = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 920px) {
    display: none;
  }
`;

export const NavProfile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
  position: relative;
  cursor: pointer;
`;

export const NavDp = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: ${Primary[500]};
  margin-right: 10px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Name = styled.div`
  white-space: nowrap;
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  padding-left: 5px;
`;

export const NavDropdown = styled.ul`
  position: absolute;
  background: ${Primary[900]};
  top: 0;
  right: 0;
  margin-top: 35px;
  padding: 0px;
  border-radius: 5px;
  overflow: hidden;
  display: none;
  > li {
    list-style-type: none;
    padding: 10px 30px;
  }
  a {
    font-size: 14px;
    color: #fff;
    text-decoration: none;
  }
  li:hover {
    color: #ffffff;
    background: ${Primary[700]};
  }
  &.active {
    display: block;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid ${Grey[900]};
  padding-left: 20px;
  border-radius: 5px;
  ${InputStyle} {
    width: 250px;
    background: none;
    ::placeholder {
      color: ${Grey[400]};
    }
    &:focus {
      outline: 0;
    }
    @media (max-width: 1000px) {
      width: 180px;
    }
  }
`;
export const MobileIcon = styled.button`
  display: none;
  background: none;
  border: none;
  @media (max-width: 920px) {
    display: block;
  }
`;

export const MobileWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: ${Primary[900]};
  position: fixed;
  top: 0px;
  display: none;
  &.active {
    display: block;
  }
`;

export const MobileContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
`;

export const MobileSearch = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: 0.5px solid ${Grey[700]};
  border-radius: 5px;
  ${InputStyle} {
    background: none;
    ::placeholder {
      color: ${Grey[400]};
    }
    &:focus {
      outline: 0;
    }
  }
`;
export const MobileMenu = styled.div`
  > ul {
    padding: 0px;
  }
  > ul > li {
    display: block;
    text-align: center;
    padding: 20px 0;
  }
  a {
    color: #fff;
    text-decoration: none;
  }
`;
export const MobileLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
`;
export const ProfileMenu = styled.div`
  > ul {
    margin-bottom: 150px;
    margin-top: -80px;
    padding: 0px;
  }
  > ul > li {
    display: block;
    text-align: center;
    padding: 20px 0;
  }
  a {
    color: #fff;
    text-decoration: none;
  }
`;
