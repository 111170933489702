import styled from "styled-components";
import { Primary } from "../../../helpers/Colors";

export const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 50px;
`;

export const SectionHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SliderNav = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: ${Primary[900]};
  color: #fff;
  position: absolute;
  z-index: 100;
  /* top: 50%; */
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: 570px) {
    width: 50px;
    height: 50px;
  }
`;

export const SliderNavLeft = styled(SliderNav)`
  left: 0;
  margin-left: -40px;
  @media (max-width: 570px) {
    margin-left: -15px;
  }
`;

export const SliderNavRight = styled(SliderNav)`
  right: 0;
  margin-right: -40px;
  @media (max-width: 570px) {
    margin-right: -15px;
  }
`;
