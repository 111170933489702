import React from "react";
import Typography from "../../atoms/Typography";
import ForgotPasswordForm from "../../organisms/forms/ForgotPasswordForm";
const ForgotPassword = () => {
  return (
    <div>
      <Typography variant="h1">Forgot Password</Typography>
      <Typography variant="p1">
        Enter your account email address and we will send you a password reset
        link.
      </Typography>
      <ForgotPasswordForm />
    </div>
  );
};

export default ForgotPassword;
