import React from "react";
import ButtonStyle from "./ButtonStyle";
import { CircularProgress } from "@mui/material";

const index = ({
  label,
  type = "solid",
  size,
  color = "primary",
  showIcon = "none",
  icon = null,
  block = false,
  state = "",
  className = "",
  onClick,
  style,
  isLoading,
  ...props
}) => {
  if (isLoading) {
    return (
      <ButtonStyle
        type={type}
        size={size}
        color={color}
        showIcon={showIcon}
        state={state}
        block={block}
        style={style}
        onClick={onClick}
        className="loading"
        {...props}
      >
        <CircularProgress color="inherit" size={18} />
        {showIcon === "left" && <span style={{ paddingRight: 5 }}>{icon}</span>}
        {label}
        {showIcon === "right" && <span style={{ paddingLeft: 5 }}>{icon}</span>}
      </ButtonStyle>
    );
  }
  return (
    <ButtonStyle
      type={type}
      size={size}
      color={color}
      showIcon={showIcon}
      state={state}
      block={block}
      style={style}
      className={className}
      onClick={onClick}
      {...props}
    >
      {showIcon === "left" && <span style={{ paddingRight: 5 }}>{icon}</span>}
      {label}
      {showIcon === "right" && <span style={{ paddingLeft: 5 }}>{icon}</span>}
    </ButtonStyle>
  );
};

export default index;
