import { GlobalStyle } from "./helpers/GlobalStyle";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPage from "./components/pages/MainPage";
import AuthPage from "./components/pages/AuthPage";
import Login from "./components/templates/auth/Login";
import Register from "./components/templates/auth/Register";
import ForgotPassword from "./components/templates/auth/ForgotPassword";
import ResetPassword from "./components/templates/auth/ResetPassword";
import ScrollToTop from "./helpers/ScrollToTop";
import Toast from "./components/molecules/toast/Toast";
import { useContext } from "react";
import { ToastContext } from "./context/ToastContext";

function App() {
  const { toastShow, toastType, toastMsg } = useContext(ToastContext);

  return (
    <BrowserRouter>
      <Toast show={toastShow} type={toastType} msg={toastMsg} />
      <GlobalStyle />
      <ScrollToTop />
      <Routes>
        <Route path="*" element={<MainPage />} />

        {/* <Route path="/watch/:url" element={<ResetPassword />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
