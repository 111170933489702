import styled from "styled-components";

export const Breadcrumbs = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  p {
    text-transform: capitalize;
  }

  @media (max-width: 710px) {
    display: none;
  }
`;
