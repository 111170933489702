import styled from "styled-components";
import { Grey, Primary } from "../../../helpers/Colors";
import { InputStyle } from "../../atoms/Input/InputStyle";
import Typography from "../../atoms/Typography";

export const MovieHeroSection = styled.section`
  position: relative;
  background-size: cover !important;
  background-position: center;
  width: 100%;
  height: 650px;
  top: 0;
`;
export const MovieHeroOverlay = styled.div`
  position: absolute;
  /* display: flex; */
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    356.55deg,
    #0a0118 8.33%,
    rgba(10, 1, 24, 0.49) 70.03%
  );
`;
export const MovieHeroWrapper = styled.div`
  width: 100%;
  max-width: 768px;
  padding-top: 4px;
  h1 {
    margin-bottom: 0px;
  }
  @media (max-width: 768px) {
    width: 100%;
    h1 {
      font-size: 32px;
    }
  }
`;
export const MovieRate = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const PlayMovieIcon = styled.div`
  position: absolute;
  font-size: 50px;
  left: 50%;
  top: 50%;
  margin-top: -25px;
  margin-left: -25px;
  cursor: pointer;
  z-index: 10;
  @media (max-width: 768px) {
    position: relative;
    left: 0px;
    margin-left: 0px;
    margin-top: 30px;
    font-size: 30px;
  }
`;
export const MovieDataSection = styled.section`
  > div {
    display: flex;
    justify-content: space-between;
    column-gap: 30px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    column-gap: 0;
    > div {
      flex-direction: column;
    }
  }
`;

export const MovieDetails = styled.div`
  flex: 70%;
`;
export const RelatedSection = styled.div`
  flex: 30%;
`;
export const MovieReaction = styled.div`
  display: flex;
  text-transform: uppercase;
  > div {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  @media (max-width: 768px) {
    text-transform: capitalize;
    justify-content: space-between;
    > div {
      flex-direction: column;
      ${Typography} {
        font-size: 12px;
      }
    }
  }
`;

export const ReactionText = styled.p`
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const MovieBiography = styled.div`
  display: flex;
  @media (max-width: 768px) {
  }
`;
export const BoldText = styled.span`
  font-weight: 600;
  color: ${Grey[50]};
  @media (max-width: 768px) {
    font-weight: 500;
  }
`;
export const CommentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    border-bottom: 0.5px solid ${Grey[700]};
    margin: 20px 0;
  }
`;
export const CommentIcon = styled.span`
  display: block;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const CommentsWrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
    &.active {
      display: block;
    }
  }
`;
export const NewCommentHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;
export const Dp = styled.div`
  border-radius: 5px;
  width: 50px;
  height: 50px;
  background: ${Primary[700]};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
  @media (max-width: 768px) {
    border-radius: 3px;
    width: 50px;
    height: 40px;
  }
`;
export const NewCommentWrapper = styled.div`
  display: flex;
  /* align-items: center; */
  background-color: ${Primary[400]};
  width: 100%;
  height: 50px;
  border-radius: 5px;
  > div {
    width: 100%;
  }
  ${InputStyle} {
    height: 50px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    ::placeholder {
      color: ${Grey[400]};
    }
    &:focus {
      outline: 0;
    }
  }

  @media (max-width: 768px) {
    height: 40px;
    ${InputStyle} {
      height: 40px;
    }
  }
`;
export const CommentHolder = styled.div`
  display: flex;
  padding: 20px 0;
  gap: 15px;
  @media (max-width: 678px) {
    border-bottom: 0.5px solid ${Grey[700]};
    margin: 20px 0;
  }
`;
export const SingleComment = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Username = styled.span`
  font-size: 16px;
  color: ${Grey[100]};
  margin-right: 10px;
`;
export const CommentTime = styled.span`
  font-size: 14px;
  color: ${Grey[300]};
`;
export const CommentText = styled.p`
  font-size: 16px;
  color: ${Grey[50]};
`;
export const ReplyComment = styled.div`
  display: none;
  height: 50px;
  width: 100%;
  border-radius: 5px;
  background-color: ${Primary[400]};
  > div {
    width: 100%;
  }
  ${InputStyle} {
    height: 50px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    ::placeholder {
      color: ${Grey[400]};
    }
    &:focus {
      outline: 0;
    }
  }
  &.active {
    display: flex;
  }
  @media (max-width: 768px) {
    height: 40px;
    ${InputStyle} {
      height: 40px;
    }
  }
`;
export const MoreComment = styled.div`
  font-size: 16px;
  color: ${Primary[500]};
  font-weight: 600;
`;
export const ParentPayment = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: ${Primary[900]};
  box-shadow: 20px;
  padding: 25px;
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 280px;
    padding: 15px;
    border-radius: 5px;
  }
`;
export const PaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  /* align-items: center; */
  justify-content: center;
`;
export const PaymentOption = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const PaymentHeader = styled.div`
  display: flex;
  justify-content: center;
`;

export const ItemList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.total {
    border-top: 1px solid ${Primary[500]};
    padding: 10px 0px;
  }
  p {
    margin: 0px;
  }
`;
