import React from "react";
import MovieBody from "./MovieBody";
import MovieHero from "./MovieHero";
import useFetch from "../../../api/useFetch";
import { useParams } from "react-router-dom";
import CardSkeleton from "../../molecules/cards/skeleton";

const Movies = () => {
  let { url } = useParams();
  const { data, loading, error } = useFetch(`/movies/${url}`);

  if (loading) {
    return (
      <div>
        <CardSkeleton
          height="500px"
          style={{
            borderRadius: "0px",
          }}
        />
      </div>
    );
  }

  return (
    <>
      <MovieHero data={data.data.movie} />
      <MovieBody data={data.data.movie} similar={data.data.similar} />
    </>
  );
};

export default Movies;
