import React from "react";
import { Link } from "react-router-dom";
import { Wrapper } from "./NotFoundStyle";
import icon from "../../../assets/images/404.png";
import Typography from "../../atoms/Typography";
import Button from "../../atoms/Button";

function NotFound() {
  return (
    <Wrapper>
      <img src={icon} width={200} />
      <Typography variant="h2" weight="bold">
        Oops!
      </Typography>

      <Typography variant="p1">PAGE NOT FOUND ON SERVER</Typography>
      <Typography variant="p2">
        The link you followed is either outdated, inaccurate, or broken.
      </Typography>

      <Link to="/">
        <Button label="Go Back Home" />
      </Link>
    </Wrapper>
  );
}

export default NotFound;
