import { Container } from "../../../helpers/GlobalStyle";
import { MovieSection } from "./AccountStyle";
import ReactPlayer from "react-player";
import { allData } from "../../../api/api";
import CardSkeleton from "../../molecules/cards/skeleton";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { ToastContext } from "../../../context/ToastContext";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "../../atoms/Typography";
import moment from "moment/moment";

const MoviePlayer = () => {
  const { url } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [movie, setMovie] = useState(null);
  const { getUser } = useContext(AuthContext);
  const user = getUser();
  const { displayToast } = useContext(ToastContext);

  let navigate = useNavigate();

  const getMovie = async () => {
    const data = await allData(user.token, `/rented-movies/${url}`);
    if (data.status === "success") {
      setMovie(data.data);
    } else {
      displayToast("error", data.message);
      navigate("/account?show=movies");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getMovie();
  }, []);

  if (isLoading) {
    return (
      <MovieSection>
        <Container>
          <CardSkeleton height="500px" />
        </Container>
      </MovieSection>
    );
  }

  return (
    <MovieSection>
      <Container>
        <ReactPlayer
          //   ref={playerRef}
          config={{
            file: { attributes: { controlsList: "nodownload" } },
          }}
          width="100%"
          //   height="450px"
          playing={true}
          url={movie.movieID.movie}
          controls
          // onProgress={(played) => onPlaying(played)}
          // progressInterval={60000}
          // progressInterval={5000}
          // onEnded={handleEnded}
        />
        <Typography
          variant="h3"
          weight="bold"
          style={{ textTransform: "capitalize" }}
        >
          {movie.movieID.title}
        </Typography>
        <Typography variant="p2">{movie.movieID.synopsis}</Typography>
        <Typography variant="p2">
          Ren Expires on: {moment(movie.endDate).format("Do MMM, YY")}
        </Typography>
      </Container>
    </MovieSection>
  );
};

export default MoviePlayer;
