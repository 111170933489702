import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "../../../helpers/GlobalStyle";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import MovieCard from "./../../molecules/cards/MovieCard";
import {
  CarouselWrapper,
  SliderNavLeft,
  SliderNavRight,
  SectionHead,
} from "./MovieCarouselStyle";
import FeatherIcon from "feather-icons-react";
import Typography from "./../../atoms/Typography";
import moment from "moment/moment";
import useFetch from "../../../api/useFetch";
import { AuthContext } from "../../../context/AuthContext";

const MovieCarousel = ({ title, seeAllUrl, apiUrl, ...props }) => {
  const { data, loading } = useFetch(apiUrl);
  const { getUser } = useContext(AuthContext);
  const user = getUser();

  const [owl, setOwl] = useState();
  const settings = {
    responsive: {
      0: {
        items: 2,
      },

      1366: {
        items: 4,
      },
    },
  };

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <div {...props}>
      <Container>
        <SectionHead>
          <Typography variant="h2" weight="bold">
            {title}
          </Typography>
          <Link to={seeAllUrl}>See all</Link>
        </SectionHead>

        <CarouselWrapper>
          <SliderNavLeft dir="left" onClick={() => owl.prev()}>
            <FeatherIcon icon="chevron-left" />
          </SliderNavLeft>

          <SliderNavRight dir="right" onClick={() => owl.next()}>
            <FeatherIcon icon="chevron-right" />
          </SliderNavRight>
          <OwlCarousel
            className="owl-theme"
            loop={false}
            margin={20}
            responsive={settings.responsive}
            nav={false}
            dots={true}
            ref={(slider) => setOwl(slider)}
          >
            {data.data &&
              data.data.map((movie, i) => (
                <MovieCard
                  key={i}
                  movie={movie}
                  id={movie._id}
                  url={movie.url}
                  rating={5}
                  cover={movie.poster.url}
                  title={movie.title}
                  genre={movie.genre[0].name}
                  price={`${
                    user
                      ? Number(movie.price[user.data.currency]).toLocaleString()
                      : Number(movie.price.NGN).toLocaleString()
                  }`}
                  year={moment(movie.releaseDate).format("YYYY")}
                />
              ))}
          </OwlCarousel>
        </CarouselWrapper>
      </Container>
    </div>
  );
};

export default MovieCarousel;
