import React from "react";
import { Link } from "react-router-dom";
import {
  SimilarMovieWrapper,
  SimilarMovieImg,
  ContentWrapper,
  SimilarMovieImgWrapper,
} from "./SimilarMoviesStyle";
import { MovieRating, PriceWrap } from "../MovieCard/MovieCardStyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
// import { faHeart } from "@fortawesome/free-regular-svg-icons";
import Typography from "./../../../atoms/Typography";
import { Grey, Success } from "../../../../helpers/Colors";
// import Button from "./../../../atoms/Button";

const truncate = (input) =>
  input.length > 25 ? `${input.substring(0, 25)}...` : input;

const index = ({ id, url, rating, cover, title, genre, price, ...props }) => (
  <SimilarMovieWrapper>
    <SimilarMovieImgWrapper>
      <Link to={url}>
        <SimilarMovieImg style={{ background: `url(${cover})` }}>
          <MovieRating>
            {rating} <FontAwesomeIcon icon={faStar} color="#FFD700" />
          </MovieRating>
        </SimilarMovieImg>
      </Link>
    </SimilarMovieImgWrapper>
    <ContentWrapper>
      <Link to={url}>
        <Typography variant="h4">{truncate(title)}</Typography>
      </Link>
      <Typography
        variant="p2"
        style={{
          color: Grey[400],
          margin: "0px",
          marginTop: "10px",
          textTransform: "capitalize",
        }}
      >
        {/* {genre.join(", ")} */}
        {genre}
      </Typography>

      <PriceWrap>
        <Typography weight="bold" variant="p1" style={{ color: Success[400] }}>
          N{price}
        </Typography>
        {/* <Button
          type="text"
          label={<FontAwesomeIcon icon={faHeart} color="#f5f5f5" />}
        /> */}
      </PriceWrap>
    </ContentWrapper>
  </SimilarMovieWrapper>
);

export default index;
