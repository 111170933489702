import styled from "styled-components";
import { Grey, Info, Primary } from "../../../helpers/Colors";

export const AccountTap = styled.div`
  .MuiBox-root {
    border-color: ${Grey[900]} !important;
  }
  .MuiTabs-indicator {
    background-color: ${Primary[500]} !important;
  }
  button {
    text-transform: capitalize !important;
    font-family: "Mulish";
    font-size: 18px;
  }
  @media (max-width: 560px) {
    button {
      font-size: 14px;
    }
  }
`;

export const TableWrapper = styled.div`
  .MuiTable-root {
    /* background: red; */
  }
  .MuiTableCell-root {
    background: none !important;
    color: #fff !important;
    border-bottom: 1px solid ${Primary[900]};
  }
  .MuiTablePagination-root {
    color: #fff !important;
  }
  .MuiTableCell-body {
    color: ${Grey[100]} !important;
  }
`;

export const FormRow = styled.div`
  display: flex;
  @media (max-width: 580px) {
    flex-direction: column;
  }
`;

export const AlertMsg = styled.div`
  padding: 20px;
  border-radius: 5px;
  margin: 10px 0px;
  background: ${Info[700]};
`;

export const MovieSection = styled.div`
  padding: 80px 0px;
`;
