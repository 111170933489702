import styled from "styled-components";
import { Primary } from "./../../../../helpers/Colors";

export const SimilarMovieWrapper = styled.div`
  width: 100%;
  /* max-width: 400px; */
  display: flex;
`;

export const SimilarMovieImgWrapper = styled.div`
  flex: 1;
  a {
    color: ${Primary[100]};
  }
`;

export const SimilarMovieImg = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
  border-radius: 5px;
  background-color: ${Primary[900]};
  background-size: cover !important;
  background-position: top !important;
  margin-bottom: 20px;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 10px 20px;
  h4 {
    text-transform: capitalize;
  }
  a {
    text-decoration: none;
    color: ${Primary[100]};
  }
`;
