import React from "react";
import Typography from "../../atoms/Typography";
import ResetPasswordForm from "../../organisms/forms/ResetPasswordForm";

const ResetPassword = () => {
  return (
    <div>
      <Typography variant="h1">Reset Password</Typography>
      <Typography variant="p1">
        Create a new password for your account
      </Typography>
      <ResetPasswordForm />
    </div>
  );
};

export default ResetPassword;
