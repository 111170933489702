import React from "react";
import RoutePaths from "../../routes";
import Navbar from "../organisms/navbar/Navbar";
import Footer from "../organisms/footer/Footer";

function MainPage(props) {
  return (
    <div>
      <Navbar />
      <RoutePaths />
      <Footer />
    </div>
  );
}

export default MainPage;
