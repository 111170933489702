import Skeleton from "@mui/material/Skeleton";
import { Primary } from "../../../../helpers/Colors";

const CardSkeleton = ({ height = "200px", width = "100%", ...props }) => {
  return (
    <div>
      <Skeleton
        variant="rounded"
        width={width}
        height={height}
        sx={{
          borderRadius: "12px",
          bgcolor: Primary[800],
        }}
        {...props}
      />
    </div>
  );
};

export default CardSkeleton;
