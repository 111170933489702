import React from "react";
import { MovieGridWrapper, MovieGridBox } from "./../favourites/FavoritesStyle";
import { Container } from "../../../helpers/GlobalStyle";
import GenreCard from "../../molecules/cards/GenreCard";
import img from "./../../../assets/images/movies/western.jpg";
import Typography from "../../atoms/Typography";
import useFetch from "../../../api/useFetch";

const genres = [
  {
    id: 1,
    url: "/movies/url",
    cover: img,
    title: "action",
  },
  {
    id: 2,
    url: "/movies/url",
    cover: img,
    title: "comedy",
  },
  {
    id: 1,
    url: "/movies/url",
    cover: img,
    title: "romance",
  },
  {
    id: 1,
    url: "/movies/url",
    cover: img,
    title: "adventure",
  },
  {
    id: 1,
    url: "/movies/url",
    cover: img,
    title: "horror",
  },
  {
    id: 1,
    url: "/movies/url",
    cover: img,
    title: "thriller",
  },
  {
    id: 1,
    url: "/movies/url",
    cover: img,
    title: "sci-fi",
  },
  {
    id: 1,
    url: "/movies/url",
    cover: img,
    title: "sports",
  },
  {
    id: 1,
    url: "/movies/url",
    cover: img,
    title: "western",
  },
  {
    id: 1,
    url: "/movies/url",
    cover: img,
    title: "drama",
  },
  {
    id: 1,
    url: "/movies/url",
    cover: img,
    title: "mystery",
  },
  {
    id: 1,
    url: "/movies/url",
    cover: img,
    title: "fantasy",
  },
];

const Genre = () => {
  const { data, loading, error } = useFetch("/genres");

  return (
    <div>
      <Container>
        <Typography variant="h2" weight="bold" style={{ marginTop: 100 }}>
          Genre
        </Typography>
        <MovieGridWrapper>
          {loading
            ? "loading..."
            : data.data.map((genre, i) => (
                <MovieGridBox>
                  <GenreCard
                    key={i}
                    url={`/movies?genreID=${genre.id}`}
                    cover={genre.img}
                    title={genre.name}
                  />
                </MovieGridBox>
              ))}
        </MovieGridWrapper>
      </Container>
    </div>
  );
};

export default Genre;
