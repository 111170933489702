import React, { useState, useContext, useEffect } from "react";
import { Container } from "../../../helpers/GlobalStyle";
import Typography from "../../atoms/Typography";
import {
  MovieDetails,
  MovieReaction,
  RelatedSection,
  MovieDataSection,
  BoldText,
  MovieBiography,
  ReactionText,
} from "./MovieStyle";
import FeatherIcon from "feather-icons-react";
import Button from "../../atoms/Button";
import { Grey, Primary } from "../../../helpers/Colors";
// import NewComment from "./NewComment";
import SimilarMovies from "../../molecules/cards/SimilarMovies";
import moment from "moment/moment";
import { AuthContext } from "../../../context/AuthContext";
import { ToastContext } from "../../../context/ToastContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faHeart } from "@fortawesome/free-solid-svg-icons";
import { allData } from "../../../api/api";

const MovieBody = ({ data, similar }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFav, setIsFav] = useState(false);
  const [liked, setLiked] = useState(false);
  const [likes, setLikes] = useState(0);
  const { getUser } = useContext(AuthContext);
  const { displayToast } = useContext(ToastContext);
  const user = getUser();

  const handleFav = async () => {
    if (user) {
      setIsLoading(true);
      const res = await allData(user.token, `/movies/favorites/${data._id}`);
      if (res.status === "success") {
        setIsFav(!isFav);
      }
      setIsLoading(false);
    } else {
      displayToast("info", "login to favourite movie");
    }
  };

  const handleLike = async () => {
    if (user) {
      // setIsLoading(true);
      const res = await allData(user.token, `/movies/likes/${data._id}`);
      if (res.status === "success") {
        let newLike = liked ? likes - 1 : likes + 1;
        setLikes(newLike);
        setLiked(!liked);
      }
      // setIsLoading(false);
    } else {
      displayToast("info", "login to like movie");
    }
  };

  useEffect(() => {
    if (user) {
      if (data.favorites) {
        let fav = data.favorites.some((fav) => fav.userID === user.data._id);
        setIsFav(fav);
      }

      if (data.likes) {
        let fav = data.likes.some((like) => like.userID === user.data._id);
        setLiked(fav);
      }
    }
    data.likes ? setLikes(data.likes.length) : setLikes(0);
  }, []);

  return (
    <>
      <MovieDataSection>
        <Container>
          <MovieDetails>
            <MovieReaction>
              <div>
                <Button
                  size="sm"
                  type="text"
                  label={
                    <FeatherIcon
                      icon="thumbs-up"
                      size="23"
                      color={`${Primary[400]}`}
                      style={{ paddingRight: "5px" }}
                      fill={liked ? Primary[500] : ""}
                    />
                  }
                  onClick={handleLike}
                />

                <ReactionText
                  variant="h6"
                  style={{ paddingRight: "30px", color: `${Grey[50]}` }}
                >
                  {likes}
                </ReactionText>
              </div>

              {/* <div>
                <Button
                  size="sm"
                  type="text"
                  label={
                    <FeatherIcon
                      icon="share-2"
                      size="23"
                      color={`${Primary[300]}`}
                      style={{ paddingRight: "5px" }}
                    />
                  }
                />

                <ReactionText
                  variant="h6"
                  style={{ paddingRight: "30px", color: `${Grey[50]}` }}
                >
                  share
                </ReactionText>
              </div> */}
              <div>
                <Button
                  size="sm"
                  type="text"
                  label={
                    <FeatherIcon
                      icon="eye"
                      size="23"
                      color={`${Primary[300]}`}
                      style={{ paddingRight: "5px" }}
                    />
                  }
                />

                <ReactionText
                  variant="h6"
                  style={{ paddingRight: "30px", color: `${Grey[50]}` }}
                >
                  {data.views} Views
                </ReactionText>
              </div>
              <div>
                <Button
                  size="sm"
                  type="text"
                  label={
                    isFav ? (
                      <FontAwesomeIcon
                        icon={isLoading ? faSpinner : faHeart}
                        className={isLoading ? "spin" : ""}
                        style={{ fontSize: "20px" }}
                        color={Primary[500]}
                      />
                    ) : (
                      <FeatherIcon
                        icon={isLoading ? "loader" : "heart"}
                        className={isLoading ? "spin" : ""}
                        color="#f5f5f5"
                        size={20}
                      />
                    )
                  }
                  onClick={handleFav}
                />

                <ReactionText variant="h6" style={{ color: `${Grey[50]}` }}>
                  favorite
                </ReactionText>
              </div>
            </MovieReaction>
            <Typography
              variant="h3"
              weight={"bold"}
              style={{ display: "block", color: `${Grey[50]}` }}
            >
              Synopsis
            </Typography>
            <MovieBiography>
              <Typography variant="p1" style={{ color: `${Grey[200]}` }}>
                {data.synopsis}
              </Typography>
            </MovieBiography>

            <Typography
              variant="p1"
              style={{ color: `${Grey[200]}`, textTransform: "capitalize" }}
            >
              Cast :<BoldText>{data.casts.toString()}</BoldText>
            </Typography>

            <Typography
              variant="p1"
              style={{ color: `${Grey[200]}`, textTransform: "capitalize" }}
            >
              Genre : <BoldText>{data.genreID.name}</BoldText>
            </Typography>

            <Typography variant="p1" style={{ color: `${Grey[200]}` }}>
              Date :
              <BoldText> {moment(data.releaseDate).format("YYYY")}</BoldText>
            </Typography>
            {/* <hr
              style={{ border: `0.5px solid ${Grey[700]}`, margin: "20px 0" }}
            /> */}
            {/* <NewComment data={data} /> */}
          </MovieDetails>

          <RelatedSection>
            <Typography
              variant="h4"
              weight={"bold"}
              style={{
                display: "block",
                color: `${Grey[50]}`,
                marginBottom: "15px",
              }}
            >
              Similar Movies
            </Typography>
            {similar.map((movie, key) => (
              <SimilarMovies
                key={key}
                id={movie._id}
                url={`/movies/${movie.url}`}
                rating={4.5}
                cover={movie.poster.url}
                title={movie.title}
                genre={movie.genre[0].name}
                price={Number(movie.price.NGN).toLocaleString()}
              />
            ))}
          </RelatedSection>
        </Container>
      </MovieDataSection>
    </>
  );
};

export default MovieBody;
