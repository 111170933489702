import React from "react";
import { Container } from "../../../helpers/GlobalStyle";
import Typography from "./../../atoms/Typography";
import delImg from "./../../../assets/images/delete.png";

const DeleteAccount = () => {
  return (
    <Container style={{ paddingTop: 50 }}>
      <Typography variant="h1">How to delete your account</Typography>
      <ol>
        <li>Log into your account</li>
        <li>Navigate to Profile Settings</li>
        <li>Click on "Delete My Accout"</li>
      </ol>

      <Typography variant="p1">follow the screenshot below: </Typography>

      <img src={delImg} width="100%" />
    </Container>
  );
};

export default DeleteAccount;
