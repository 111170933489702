import styled from "styled-components";
import { Primary } from "../../../helpers/Colors";

const LoginWithButton = styled.button`
  width: 100%;
  cursor: pointer;
  text-align: center;
  background: none;
  font-size: 14px;
  padding: 15px;
  border: 2px solid ${Primary[500]};
  color: ${Primary[500]};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: ${Primary[500]};
    color: #fff;
  }
`;

export default LoginWithButton;
