import styled from "styled-components";
import { Grey, Primary } from "../../../helpers/Colors";
import heroImg from "./../../../assets/images/home_hero_bg.jpg";

export const HomeHero = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  background: ${Primary[900]};
  background-image: url(${heroImg});
  background-size: cover !important;
`;

export const HomeHeroText = styled.div`
  width: 50%;
  padding-top: 160px;
  h1 {
    margin: 0px;
    font-size: 62px;
  }
  @media (max-width: 768px) {
    width: 100%;
    h1 {
      font-size: 42px;
      text-align: center;
    }
    p {
      text-align: center;
    }
    button {
      width: 100%;
    }
  }
`;

export const HomeHeroOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    286.04deg,
    rgba(10, 1, 24, 0.6) 0.7%,
    #0a0118 72.94%
  );
`;

export const ComingSoon = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  background: ${Primary[900]};
  background-size: cover !important;
  background-position: center !important;
`;

export const ComingSoonOverlay = styled.div`
  /* position: absolute; */
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0.8);
  padding: 70px 0px;
`;

export const ComingSoonImgDiv = styled.div`
  width: 100%;
  height: 700px;
  background: ${Primary[900]};
  border-radius: 10px;
  background-size: cover !important;
  background-position: top !important;
  @media (max-width: 769px) {
    height: 300px;
  }
`;

export const ComingSoonContent = styled.div`
  padding: 80px;
  p:nth-child(1) {
    color: ${Primary[500]};
  }
  p {
    color: ${Grey[400]};
  }
  h2 {
    font-size: 60px;
    margin: 0px;
  }
  &.mobileapp {
    padding: 0px;
    padding-top: 180px;
  }
  @media (max-width: 769px) {
    padding: 0px;
    h2 {
      font-size: 38px;
    }
    &.mobileapp {
      padding-top: 50px;
    }
  }
`;

export const DownloadAppWrapper = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: center;
  @media (max-width: 769px) {
    display: none;
  }
`;
