import { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Input } from "../../atoms/Input";
import Button from "../../atoms/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContext } from "../../../context/ToastContext";
import { AuthContext } from "../../../context/AuthContext";

const ResetPasswordForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { displayToast } = useContext(ToastContext);
  const { resetPassword } = useContext(AuthContext);
  const navigate = useNavigate();
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  const otp = params.get("otp");

  const formik = useFormik({
    initialValues: {
      password: "",
      cpassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Required"),
      cpassword: Yup.string()
        .required("Can not be empty")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await resetPassword({ otp, password: values.password });
        displayToast("success", "login with your new password");
        navigate("/login");
      } catch (err) {
        // handleNotify(err.response.data.message, "error");
        displayToast("error", err.response.data.message);
      }
      setIsLoading(false);
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Input
          name="password"
          type="password"
          placeholder="New Password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          size="md"
          state={formik.touched.password && formik.errors.password && "error"}
          desc={
            formik.touched.password &&
            formik.errors.password &&
            formik.errors.password
          }
          style={{ marginBottom: 20 }}
        />
        <Input
          type="password"
          name="cpassword"
          placeholder="Confirm New Password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          size="md"
          state={formik.touched.cpassword && formik.errors.cpassword && "error"}
          desc={
            formik.touched.cpassword &&
            formik.errors.cpassword &&
            formik.errors.cpassword
          }
          style={{ marginBottom: 20 }}
        />
        <Button label="Submit" block={true} isLoading={isLoading} />
      </form>
    </div>
  );
};

export default ResetPasswordForm;
