import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import { Primary } from "./Colors";

export const GlobalStyle = createGlobalStyle`
    body{
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
        font-family: 'Mulish', sans-serif;
        /* font-family: 'Bebas Neue', cursive; */
        background: #0A0118;
        color: #fff;
    }

    a{
      text-decoration: none;
      color: ${Primary[500]};
    }
    .owl-dot.active span{
      background: ${Primary[400]} !important;
    }
    .owl-dot span{
      background: ${Primary[800]} !important;
    }
    @keyframes loader{
        from{
          transform:rotate(0deg)
        }
        to{
          transform:rotate(360deg)
        }
      }
    .spin{
      animation: loader 2s linear infinite;
    }

`;

export const Container = styled.div`
  position: relative;
  max-width: 1200px;
  margin: auto;
  @media (max-width: 1440px) {
    margin: auto 88px;
  }

  @media (max-width: 100px) {
    margin: auto 56px;
  }

  @media (max-width: 567px) {
    margin: auto 20px;
  }
`;

export const Row = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  > div {
    flex: 1;
  }
  @media (max-width: 769px) {
    flex-direction: column;
  }
`;

// export default GlobalStyle;
