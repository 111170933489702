import React from "react";
import Typography from "../../atoms/Typography";
import PersonalDetailsForm from "../../organisms/forms/PersonalDetailsForm";
import PasswordSettingsForm from "../../organisms/forms/PasswordSettingsForm";
import { Primary } from "../../../helpers/Colors";

const Profile = () => {
  return (
    <div>
      {/* <Row> */}

      <div style={{ padding: 10 }}>
        <Typography variant="h4" weight="bold">
          Personal Details
        </Typography>
        <PersonalDetailsForm />
      </div>
      <div style={{ padding: 10 }}>
        <Typography variant="h4" weight="bold">
          Password Settings
        </Typography>
        <PasswordSettingsForm />

        <Typography
          variant="small1"
          style={{ color: Primary[500], marginTop: 100 }}
        >
          Delete My Account
        </Typography>
      </div>

      {/* </Row> */}
    </div>
  );
};

export default Profile;
