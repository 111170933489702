import styled from "styled-components";
import {
  Primary,
  Secondary,
  Grey,
  Success,
  Error,
  Warning,
  Info,
} from "../../../helpers/Colors";

const ButtonStyle = styled.button`
  font-weight: 500;
  font-size: ${(props) =>
    props.size === "md" ? "14px" : props.size === "sm" ? "12px" : "16px"};
  padding: ${(props) =>
    props.size === "md"
      ? "14px 20px"
      : props.size === "sm"
      ? "10px 14px"
      : "18px 24px"};
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: ${(props) => (props.block ? "100%" : "auto")};
  background: ${(props) =>
    props.type === "outline" || props.type === "text"
      ? "none"
      : props.state === "disable"
      ? Grey[100]
      : props.color === "secondary"
      ? Secondary[500]
      : props.color === "grey"
      ? Grey[500]
      : props.color === "success"
      ? Success[500]
      : props.color === "error"
      ? Error[500]
      : props.color === "warning"
      ? Warning[500]
      : props.color === "info"
      ? Info[500]
      : Primary[500]};
  color: ${(props) =>
    props.state === "disable"
      ? Grey[300]
      : props.type !== "outline" && props.type !== "text"
      ? "#fff"
      : props.color === "secondary"
      ? Secondary[500]
      : props.color === "grey"
      ? Grey[500]
      : props.color === "success"
      ? Success[500]
      : props.color === "error"
      ? Error[500]
      : props.color === "warning"
      ? Warning[500]
      : props.color === "info"
      ? Info[500]
      : props.type !== "outline" || props.type !== "text"
      ? Primary[500]
      : "#fff"};
  border: ${(props) =>
    props.type === "outline"
      ? `2px solid ${
          props.state === "disable"
            ? Grey[300]
            : props.color === "secondary"
            ? Secondary[500]
            : props.color === "grey"
            ? Grey[500]
            : props.color === "success"
            ? Success[500]
            : props.color === "error"
            ? Error[500]
            : props.color === "warning"
            ? Warning[500]
            : props.color === "info"
            ? Info[500]
            : Primary[500]
        }`
      : "none"};

  &:hover {
    background: ${(props) =>
      props.state === "disable" && props.type === "text"
        ? "none"
        : props.state === "disable"
        ? Grey[100]
        : props.type === "text"
        ? "none"
        : props.color === "secondary"
        ? Secondary[700]
        : props.color === "grey"
        ? Grey[700]
        : props.color === "success"
        ? Success[700]
        : props.color === "error"
        ? Error[700]
        : props.color === "warning"
        ? Warning[700]
        : props.color === "info"
        ? Info[700]
        : Primary[700]};

    border: ${(props) =>
      props.type === "outline"
        ? `2px solid ${
            props.state === "disable"
              ? Grey[300]
              : props.color === "secondary"
              ? Secondary[700]
              : props.color === "grey"
              ? Grey[700]
              : props.color === "success"
              ? Success[700]
              : props.color === "error"
              ? Error[700]
              : props.color === "warning"
              ? Warning[700]
              : props.color === "info"
              ? Info[700]
              : Primary[700]
          }`
        : "none"};

    color: ${(props) =>
      props.state === "disable"
        ? Grey[300]
        : props.type !== "text"
        ? "#fff"
        : props.color === "secondary"
        ? Secondary[700]
        : props.color === "grey"
        ? Grey[700]
        : props.color === "success"
        ? Success[700]
        : props.color === "error"
        ? Error[700]
        : props.color === "warning"
        ? Warning[700]
        : props.color === "info"
        ? Info[700]
        : props.type !== "outline" || props.type !== "text"
        ? Primary[700]
        : "#fff"};
  }

  &:disabled {
    opacity: 0.7;
    &:hover {
      background: ${Grey[900]};
      color: ${Grey[50]};
    }
  }

  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  ${(props) => props.style}
`;

export default ButtonStyle;
