import React from "react";
import { MovieGridWrapper, MovieGridBox } from "../favourites/FavoritesStyle";
import MovieCard from "../../molecules/cards/MovieCard";
import { allData } from "../../../api/api";
import CardSkeleton from "../../molecules/cards/skeleton";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import moment from "moment/moment";

const MyMovies = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [movies, setMovies] = useState(null);
  const { getUser } = useContext(AuthContext);
  const user = getUser();
  const getMovies = async () => {
    const data = await allData(user.token, "/rented-movies");
    if (data.status === "success") {
      setMovies(data.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getMovies();
  }, []);

  if (isLoading) {
    return (
      <MovieGridWrapper>
        <MovieGridBox>
          <CardSkeleton />
        </MovieGridBox>
        <MovieGridBox>
          <CardSkeleton />
        </MovieGridBox>
      </MovieGridWrapper>
    );
  }

  return (
    <MovieGridWrapper>
      {movies.map((movie, i) => (
        <MovieGridBox key={i}>
          <MovieCard
            id={movie.movieID._id}
            url={`../account/movies/${movie.movieID.url}`}
            rating={4.5}
            cover={movie.movieID.poster.url}
            title={movie.movieID.title}
            genre={movie.movieID.genreID.name}
            price={Number(
              movie.movieID.price[user.data.currency]
            ).toLocaleString()}
            year={moment(movie.movieID.releaseDate).format("YYYY")}
          />
        </MovieGridBox>
      ))}
    </MovieGridWrapper>
  );
};

export default MyMovies;
