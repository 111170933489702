import { MovieGridWrapper, MovieGridBox } from "./FavoritesStyle";
import { Container } from "../../../helpers/GlobalStyle";
import MovieCard from "../../molecules/cards/MovieCard";
import img from "./../../../assets/images/movies/morbius.png";
import Typography from "../../atoms/Typography";
import Button from "../../atoms/Button";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import CardSkeleton from "../../molecules/cards/skeleton";
import { allData } from "../../../api/api";

const Favourites = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [movies, setMovies] = useState(null);
  const { getUser } = useContext(AuthContext);
  const user = getUser();

  const getMovies = async () => {
    const data = await allData(user.token, "/movies/user-favorites");
    if (data.status === "success") {
      setMovies(data.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getMovies();
  }, []);

  return (
    <div>
      <Container>
        <Typography variant="h2" weight="bold" style={{ marginTop: 100 }}>
          Favorites
        </Typography>
        <>
          {isLoading ? (
            <MovieGridWrapper>
              <CardSkeleton width="250px" height="300px" />
              <CardSkeleton width="250px" height="300px" />
            </MovieGridWrapper>
          ) : (
            <MovieGridWrapper>
              {movies.map((movie, i) => (
                <MovieGridBox>
                  <MovieCard
                    key={i}
                    movie={movie}
                    id={movie._id}
                    url={movie.url}
                    rating={5.0}
                    cover={movie.poster.url}
                    title={movie.title}
                    genre={movie.genreID.name}
                    price={Number(
                      movie.price[user.data.currency]
                    ).toLocaleString()}
                    year={movie.releaseDate}
                  />
                </MovieGridBox>
              ))}
            </MovieGridWrapper>
          )}
        </>
        {/* <Button
          label="Load More"
          type="outline"
          size="md"
          style={{
            margin: "auto",
            display: "flex",
          }}
        /> */}
      </Container>
    </div>
  );
};

export default Favourites;
