import React from "react";
import Typography from "../../atoms/Typography";
import PasswordOtpForm from "../../organisms/forms/PasswordOtpForm";

const Otp = () => {
  return (
    <div>
      <Typography variant="h1">Enter Otp</Typography>
      <Typography variant="p1">Enter the "OTP" sent to your email</Typography>
      <PasswordOtpForm />
    </div>
  );
};

export default Otp;
