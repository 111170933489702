import Home from "./../components/templates/home/Home";
import Favorites from "../components/templates/favourites/Favorites";
import Genre from "../components/templates/genre/Genre";
import Notfound from "../components/templates/not-found/NotFound";
import Movie from "../components/templates/movie/Movie";
import Movies from "../components/templates/movies/Movies";
import Account from "../components/templates/account/Account";
import FundWallet from "../components/templates/fund-wallet/FundWallet";
import Pages from "../components/templates/pages/Pages";
import MoviePlayer from "../components/templates/account/MoviePlayer";
import Privacy from "../components/templates/pages/Privacy";
import DeleteAccount from "../components/templates/pages/DeleteAccount";
import Login from "../components/templates/auth/Login";
import Register from "../components/templates/auth/Register";
import ForgotPassword from "../components/templates/auth/ForgotPassword";
import ResetPassword from "../components/templates/auth/ResetPassword";
import Otp from "../components/templates/auth/Otp";

const routes = [
  {
    public: [
      {
        path: "/",
        component: Home,
      },
      {
        path: "/movies",
        component: Movies,
      },
      {
        path: "/movies/:url",
        component: Movie,
      },
      {
        path: "/genres",
        component: Genre,
      },
      {
        path: "/pages/:url",
        component: Pages,
      },
      {
        path: "/privacy-policy",
        component: Privacy,
      },
      {
        path: "/delete-account",
        component: DeleteAccount,
      },
      {
        path: "*",
        component: Notfound,
      },
    ],
    protected: [
      {
        path: "/account",
        component: Account,
      },
      {
        path: "/account/movies/:url",
        component: MoviePlayer,
      },
      {
        path: "/favorites",
        component: Favorites,
      },
      {
        path: "/fund-wallet",
        component: FundWallet,
      },
    ],
    auth: [
      {
        path: "/login",
        component: Login,
      },
      {
        path: "/register",
        component: Register,
      },
      {
        path: "/forgot-password",
        component: ForgotPassword,
      },
      {
        path: "/reset-password",
        component: ResetPassword,
      },
      {
        path: "/otp",
        component: Otp,
      },
    ],
  },
];

export default routes;
