import React from "react";
import { Container } from "../../../helpers/GlobalStyle";
import logo from "../../../assets/images/logo.png";
import {
  Foot,
  FooterMenu,
  FooterIcons,
  FooterContentWrapper,
  FooterLogo,
  FooterBaseText,
} from "./FooterStyle";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Primary } from "../../../helpers/Colors";

const Footer = () => {
  return (
    <>
      <Foot>
        <Container>
          <div>
            <FooterLogo>
              <img src={logo} width="40" alt="logo" />
            </FooterLogo>
            <FooterContentWrapper>
              <FooterMenu>
                <li>
                  <Link to="/pages/help-center">Help Center</Link>
                </li>
                <li>
                  <Link to="/pages/terms-of-use">Terms of Use</Link>
                </li>
                <li>
                  <Link to="/pages/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link to="/pages/faqs">FAQs</Link>
                </li>
              </FooterMenu>
              <FooterIcons>
                <FeatherIcon
                  icon="twitter"
                  size="20"
                  color={Primary[500]}
                  style={{ paddingLeft: "30px" }}
                />
                <FeatherIcon
                  icon="instagram"
                  size="20"
                  color={Primary[500]}
                  style={{ paddingLeft: "30px" }}
                />
                <FeatherIcon
                  icon="facebook"
                  size="20"
                  color={Primary[500]}
                  style={{ paddingLeft: "30px" }}
                />
              </FooterIcons>
            </FooterContentWrapper>
            <FooterBaseText>
              Copyrights. Owosh. All Rights Reserved
            </FooterBaseText>
          </div>
        </Container>
      </Foot>
    </>
  );
};

export default Footer;
