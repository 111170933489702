import axios from "../axios/axios";

//new
export const create = async (url, token, data) => {
  try {
    const res = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

//all
export const allData = async (token, url) => {
  try {
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

//get single record
export const singleRecord = async (token, url) => {
  try {
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

//get ip
export const getIP = async () => {
  try {
    const res = await axios.get(
      `https://ipinfo.io?token=${process.env.REACT_APP_IPINFO_TOKEN}`
    );
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

//update record
export const update = async (token, url, data) => {
  try {
    const res = await axios.patch(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (err) {
    return err.response.data;
  }
};

//trash
export const deleteRecord = async (url, token, ids) => {
  try {
    const res = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { ids },
    });
    return res;
  } catch (err) {
    return err.response.data;
  }
};

//restore
export const restore = async (token, url, ids) => {
  console.log(ids);
  try {
    const res = await axios.put(
      url,
      { ids },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (err) {
    return err.response.data;
  }
};

//permanently trash
export const permDel = async (token, url, ids) => {
  try {
    const res = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { ids },
    });
    return res;
  } catch (err) {
    return err.response.data;
  }
};

//handle data with file upload
export const dataWithImg = async (url, formData, token) => {
  try {
    const res = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return err.response.data;
  }
};

export const uplImg = async (url, formData, token) => {
  try {
    const res = await axios.patch(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (err) {
    return err.response.data;
  }
};
