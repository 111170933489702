import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Typography from "./../../atoms/Typography";
import { Grey } from "../../../helpers/Colors";
import Button from "../../atoms/Button";
import FeatherIcon from "feather-icons-react";
import { TableWrapper } from "./AccountStyle";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { AuthContext } from "../../../context/AuthContext";
import axios from "../../../axios/axios";
import moment from "moment/moment";

const columns = [
  { id: "desc", label: "Description", minWidth: 170 },
  {
    id: "amount",
    label: "Amount",
    minWidth: 100,
    format: (value) => value.toLocaleString(),
  },
  {
    id: "createdAt",
    label: "Date",
    minWidth: 170,
    align: "right",
  },
];

const getBalance = (transactions) => {
  const sumOfCreditAmounts = transactions
    .filter((transaction) => transaction.type === "credit")
    .reduce((total, transaction) => total + transaction.amount, 0);

  const sumOfDebitAmounts = transactions
    .filter((transaction) => transaction.type === "debit")
    .reduce((total, transaction) => total + transaction.amount, 0);

  const balance = sumOfCreditAmounts - sumOfDebitAmounts;
  return balance;
};

const Wallet = () => {
  const { getUser, refreshUser } = useContext(AuthContext);
  const user = getUser();
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getCurrencySymbol = () =>
    user.data.currency === "naira" ? (
      <>&#x20a6;</>
    ) : user.data.currency === "dollars" ? (
      <>$</>
    ) : user.data.currency === "ghana cedis" ? (
      <>&#8373;</>
    ) : user.data.currency === "kenya shillings" ? (
      <>KES </>
    ) : user.data.currency === "south africa rand" ? (
      <>R </>
    ) : user.data.currency === "zambia kwacha" ? (
      <>ZK </>
    ) : user.data.currency === "tanzania shillings" ? (
      <>TSH </>
    ) : (
      ""
    );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchUser = async () => {
    setIsLoading(true);
    await refreshUser();
    const refreshedData = getUser();
    setUserData(refreshedData.data);
    setIsLoading(false);
  };

  const fetchTrans = async () => {
    setIsLoading(true);
    const res = await axios.get("/transactions", {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    res.data.data.map((val) => {
      val.createdAt = moment(val.createdAt).format("ddd MMM DD, YYYY - H:m:s");
    });
    setTransactions(res.data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUser();
    fetchTrans();
  }, []);

  if (isLoading) {
    return <div>loading...</div>;
  }

  return (
    <div>
      <Typography variant="p1" style={{ color: Grey[500] }}>
        Available Balance
      </Typography>
      <Typography
        variant="h2"
        weight="bold"
        style={{ fontSize: 40, marginTop: 0 }}
      >
        {/* {getCurrencySymbol()} */}
        {`${userData.currency === null ? "" : userData.currency} ${
          transactions && getBalance(transactions).toLocaleString()
        }`}
      </Typography>
      <Link to="/fund-wallet">
        <Button
          showIcon="left"
          icon={<FeatherIcon icon="plus" size="16" />}
          size="md"
          label="Fund Wallet"
        />
      </Link>
      <Typography variant="h3" weight="semi-bold">
        Transactions
      </Typography>

      {/* table */}
      <TableWrapper>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            background: "#0A0118",
            color: "#fff",
          }}
        >
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id !== "amount" ? (
                                column.format && typeof value === "number" ? (
                                  column.format(value)
                                ) : (
                                  value
                                )
                              ) : (
                                <span
                                  style={{
                                    color:
                                      row.type === "debit" ? "red" : "green",
                                  }}
                                >
                                  {getCurrencySymbol()}
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </span>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={transactions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </TableWrapper>
    </div>
  );
};

export default Wallet;
