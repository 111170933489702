import React from "react";
import { Outlet, Link } from "react-router-dom";
import logo from "./../../assets/images/logo.png";
import { PageWrapper, PageWrapperOverlay, FormWrapper } from "./AuthPageStyle";

const AuthPage = () => (
  <PageWrapper>
    <PageWrapperOverlay>
      <FormWrapper>
        <Link to="/">
          <img alt="Owosh Loho" width={40} src={logo} />
        </Link>
        <Outlet />
      </FormWrapper>
    </PageWrapperOverlay>
  </PageWrapper>
);

export default AuthPage;
