import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "../../../helpers/GlobalStyle";
import {
  Nav,
  NavLinks,
  NavMenu,
  SearchWrapper,
  MobileMenu,
  MobileWrapper,
  MobileContentWrapper,
  MobileSearch,
  MobileIcon,
  MobileLinks,
  ProfileMenu,
  NavDp,
  Name,
  NavProfile,
  NavDropdown,
} from "./NavbarStyle";
import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import Button from "../../atoms/Button";
import { InputStyle } from "../../atoms/Input/InputStyle";
import FeatherIcon from "feather-icons-react";
import { Grey, Primary } from "../../../helpers/Colors";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useSearchParams } from "react-router-dom";

const Navbar = () => {
  let navigate = useNavigate();
  const [src, setSrc] = useState("");
  const [showNav, setShowNav] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const { getUser, logout } = useContext(AuthContext);
  const [searchParams, setSeachParams] = useSearchParams();
  const user = getUser();

  const logoutUser = () => {
    logout();
    navigate("/login");
  };

  useEffect(() => {
    setSrc(searchParams.get("q"));
  }, []);

  return (
    <>
      <Nav>
        <Container>
          <div style={{ display: "flex" }}>
            <div>
              <Link to="/">
                <img src={logo} width="40" alt="logo" />
              </Link>
            </div>

            <NavMenu>
              <ul>
                <li>
                  <Link to="/"> Home</Link>
                </li>
                <li>
                  <Link to="/favorites"> Favorites</Link>
                </li>
                <li>
                  <Link to="/genres">Genres</Link>
                </li>
              </ul>
            </NavMenu>
          </div>

          <NavLinks>
            <form action="/movies" method="get">
              <SearchWrapper>
                <FeatherIcon icon="search" size="20" color={Grey[400]} />
                <InputStyle
                  placeholder="Search Movies"
                  value={src}
                  onChange={(e) => setSrc(e.target.value)}
                  name="q"
                />
              </SearchWrapper>
            </form>
            {getUser() !== null ? (
              <>
                {" "}
                {/* state to show logged in desktop view menu */}
                <NavProfile onClick={() => setShowDropdown(!showDropdown)}>
                  <NavDp>{user.data.fullname.slice(0, 1)}</NavDp>
                  Hello, <Name> {user.data.fullname}</Name>
                  <FeatherIcon
                    icon={showDropdown ? "chevron-up" : "chevron-down"}
                    size="20"
                    style={{ marginLeft: "10px" }}
                  />
                  <NavDropdown className={showDropdown && "active"}>
                    <li>
                      <a href="/account?show=movies">My Movies</a>
                    </li>
                    <li>
                      <a href="/account?show=wallet">My Wallet</a>
                    </li>
                    <li>
                      <a href="/account?show=profile">User Profile</a>
                    </li>
                    <li onClick={logoutUser}>Log out</li>
                  </NavDropdown>
                </NavProfile>
              </>
            ) : (
              <>
                <Button
                  label="Login"
                  onClick={() => navigate("/login")}
                  type="text"
                  size="md"
                  style={{ color: "#fff", margin: "0 20px" }}
                />
                <Button
                  label="Sign Up"
                  size="md"
                  onClick={() => navigate("/register")}
                />
              </>
            )}
          </NavLinks>

          <MobileIcon onClick={() => setShowNav(true)}>
            {" "}
            {/* the mobile menu button */}
            <FeatherIcon icon="menu" size="24" color={Primary[50]} />
          </MobileIcon>
        </Container>
      </Nav>

      <MobileWrapper className={showNav && "active"}>
        {/* the mobile menu content */}
        <MobileContentWrapper>
          <div>
            <FeatherIcon
              onClick={() => setShowNav(false)}
              icon="x"
              size="24"
              color={Primary[50]}
              style={{ float: "right", marginBottom: "40px" }}
            />
            <form action="/movies" method="get">
              <MobileSearch>
                <FeatherIcon
                  icon="search"
                  size="20"
                  color={Grey[400]}
                  style={{ paddingLeft: "20px" }}
                />
                <InputStyle placeholder="Search Movies" name="src" />
              </MobileSearch>
            </form>
          </div>
          <MobileMenu>
            <ul>
              <li>
                <Link to="/"> Home</Link>
              </li>
              <li>
                <Link to="/favorites"> Favorites</Link>
              </li>
              <li>
                <Link to="/genres">Genres</Link>
              </li>
            </ul>
          </MobileMenu>
          {/* state to show logged in mobile view menu */}
          {getUser() !== null ? (
            <>
              <ProfileMenu>
                <ul>
                  <li>
                    <a href="/account?show=movies">My Movies</a>
                  </li>
                  <li>
                    <a href="/account?show=wallet">My Wallet</a>
                  </li>
                  <li>
                    <a href="/account?show=profile">User Profile</a>
                  </li>
                  <li onClick={logoutUser}>Log Out</li>
                </ul>
              </ProfileMenu>
            </>
          ) : (
            <>
              {" "}
              {/* state to show guest mobile view menu */}
              <MobileLinks>
                <Button
                  onClick={() => navigate("/login")}
                  label="Login"
                  type="text"
                  size="md"
                  style={{ color: "#fff", margin: "0 20px" }}
                />
                <Button
                  label="Sign Up"
                  size="md"
                  onClick={() => navigate("/register")}
                />
              </MobileLinks>
            </>
          )}
        </MobileContentWrapper>
      </MobileWrapper>
    </>
  );
};

export default Navbar;
