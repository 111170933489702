import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "../config/ProtectedRoute";
import routes from "./routes";
import AuthPage from "../components/pages/AuthPage";

function index(props) {
  return (
    <Routes>
      {/* public route */}
      {routes.map(
        (route) =>
          route.public &&
          route.public.map((val, i) => (
            <Route key={i} path={val.path} element={<val.component />} />
          ))
      )}

      {/* user/protected route */}
      <Route element={<ProtectedRoute />}>
        {routes.map(
          (route) =>
            route.protected &&
            route.protected.map((val, i) => (
              <Route key={i} path={val.path} element={<val.component />} />
            ))
        )}
      </Route>

      {/* auth routes */}
      <Route element={<AuthPage />}>
        {routes.map(
          (route) =>
            route.auth &&
            route.auth.map((val, i) => (
              <Route key={i} path={val.path} element={<val.component />} />
            ))
        )}
      </Route>
    </Routes>
  );
}

export default index;
