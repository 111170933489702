// import { useEffect } from "react";
import { Link } from "react-router-dom";
import MovieCarousel from "../../organisms/carousel/MovieCarousel";
import img from "./../../../assets/images/movies/morbius.png";
import {
  HomeHero,
  HomeHeroOverlay,
  HomeHeroText,
  ComingSoon,
  ComingSoonOverlay,
  ComingSoonImgDiv,
  ComingSoonContent,
  DownloadAppWrapper,
} from "./HomeStyle";
import Typography from "./../../atoms/Typography";
import { Container } from "../../../helpers/GlobalStyle";
import Button from "../../atoms/Button";
import ComingSoonImg from "./../../../assets/images/movies/coming_soon.jpg";
import MobileApp from "./../../../assets/images/mobileapp.png";
import { Row } from "../../../helpers/GlobalStyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { faGooglePlay, faApple } from "@fortawesome/free-brands-svg-icons";

function Home() {
  return (
    <div>
      <HomeHero>
        <HomeHeroOverlay>
          <Container>
            <HomeHeroText>
              <Typography variant="h1">
                unlimited movies, tv shows, and more.
              </Typography>
              <Typography variant="p1">
                Watch anywhere, no monthly subscrption needed.
              </Typography>
              <Link to="/movies">
                <Button label="Rent a Movie" />
              </Link>
            </HomeHeroText>
          </Container>
        </HomeHeroOverlay>
      </HomeHero>

      <MovieCarousel
        apiUrl="/movies/display?type=discover"
        title="discover"
        seeAllUrl="/movies"
      />

      <MovieCarousel
        apiUrl="/movies/display?type=new"
        title="new movies"
        seeAllUrl="/movies"
      />

      <MovieCarousel
        apiUrl="/movies/display?type=discover"
        title="trending"
        seeAllUrl="/movies"
      />

      {/* <ComingSoon style={{ backgroundImage: `url(${ComingSoonImg})` }}>
        <ComingSoonOverlay>
          <Container>
            <Row>
              <div>
                <ComingSoonImgDiv
                  style={{ backgroundImage: `url(${ComingSoonImg})` }}
                />
              </div>
              <div>
                <ComingSoonContent>
                  <Typography variant="p2">COMMING SOON</Typography>
                  <Typography variant="h2">onward</Typography>
                  <Typography variant="p1">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Omnis corrupti vitae, aliquam error numquam quos, ipsum
                    recusandae praesentium vel, tenetur magni natus eum ullam
                    nulla necessitatibus quaerat quo. Quo, quis. Lorem ipsum
                    dolor sit amet consectetur adipisicing elit. Veniam, quo
                    quaerat deleniti dolorem modi consequatur enim esse mollitia
                    numquam! Animi voluptates omnis illum vel quidem eligendi
                    voluptatem numquam fugit itaque?
                  </Typography>
                  <Button
                    label="Watch Trailer"
                    size="md"
                    style={{ marginRight: 20, marginBottom: 20 }}
                    icon={<FontAwesomeIcon icon={faPlayCircle} />}
                    showIcon="left"
                  />
                  <Button
                    label="View all coming soon"
                    size="md"
                    type="outline"
                  />
                </ComingSoonContent>
              </div>
            </Row>
          </Container>
        </ComingSoonOverlay>
      </ComingSoon> */}

      <Container>
        <Row>
          <div>
            <ComingSoonContent className="mobileapp">
              <Typography variant="h2">Download Our App</Typography>
              <Typography variant="p1">
                For better experience, download our app on playstore or appstore
              </Typography>

              <Button
                label="Download from Playstore"
                size="md"
                style={{
                  marginRight: 20,
                  marginBottom: 20,
                }}
                icon={<FontAwesomeIcon icon={faGooglePlay} color="#fff" />}
                showIcon="left"
                type="outline"
              />
              <Button
                label="Download from App Store"
                size="md"
                type="outline"
                showIcon="left"
                icon={<FontAwesomeIcon icon={faApple} color="#fff" />}
              />
            </ComingSoonContent>
          </div>

          <DownloadAppWrapper>
            <img alt="Owosh Mobile App" src={MobileApp} width={250} />
          </DownloadAppWrapper>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
