import { useEffect, memo } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Wrapper } from "./ToastStyle";

function Toast({ show, msg, type }) {
  const notify = () => {
    type === "success"
      ? toast.success(msg)
      : type === "error"
      ? toast.error(msg)
      : type === "info"
      ? toast.info(msg)
      : type === "warning"
      ? toast.warning(msg)
      : toast(msg);
  };

  useEffect(() => {
    if (show) {
      notify();
    }
  }, [notify]);
  return (
    <Wrapper>
      {/* <button onClick={notify}>Notify!</button> */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        style={{ zIndex: 100000 }}
      />
    </Wrapper>
  );
}

// export default Toast;
export default memo(Toast);
