import styled from "styled-components";
import { Grey } from "../../../helpers/Colors";
export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    color: ${Grey[200]};
  }
`;
