import styled from "styled-components";
import bgImg from "./../../assets/images/auth_bg_img.jpg";

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background: url("${bgImg}");
  background-size: cover !important;
  background-position: center !important;
`;

export const PageWrapperOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  box-sizing: border-box;
  padding: 50px 0px;
  background: linear-gradient(
    345.42deg,
    #0a0118 4.76%,
    rgba(10, 1, 24, 0.86) 113.77%
  );
`;

export const FormWrapper = styled.div`
  width: 85%;
  max-width: 380px;
`;
